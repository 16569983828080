//Popup for displaying state Requirements
    $(function(){
        $("#stateReqDetails").dialog({
               resizable: false,
               draggable: true,
               modal:true,
               autoOpen: false,
               width: 600,
               open: function(event, ui){
                   $(this).css({'max-height': 500 , 'overflow-y': 'auto'});
               }
        });
        $("#stateReq").on('click', function(){
               $('#stateReqDetails').dialog('open');
               $(".ui-dialog-titlebar-close").show();
        });
    });
